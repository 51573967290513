import { useState } from 'react';
import { TextField, Button, MenuItem, Select, FormControl } from '@mui/material';

function ContactForm() {
    const [formData, setFormData] = useState({
        school: '',
        name: '',
        age: '',
        email: '',
        phoneNumber: '',
        message: ''
    });

    const handleChange = (event: { target: { name: string; value: string; }; }) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (event: { preventDefault: () => void; }) => {
        event.preventDefault();
        // Send a request to your server with the form data
        try {
            const response = await fetch('https://z36yqxxizkk52vzf3bn4dyeqei0fnxsn.lambda-url.us-east-1.on.aws/', {
                method: 'POST',
                // headers: {
                //     'Content-Type': 'application/json'
                // },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                console.log(await response.json());
                // Handle successful submission
                console.log('Form submitted successfully');
            } else {
                // Handle server errors
                console.error('Server error');
            }
        } catch (error) {
            console.log(error);
            // Handle network errors
            console.error('Network error');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <FormControl fullWidth>
                <Select
                    name="school"
                    value={formData.school}
                    onChange={handleChange}
                    displayEmpty
                    required
                    sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
                >
                    <MenuItem sx={{display:'none'}} value="" disabled>Select School</MenuItem>
                    <MenuItem value="prodigy-creekside">Prodigy at Creekside</MenuItem>
                    <MenuItem value="prodigy-epperson">Prodigy at Epperson</MenuItem>
                    <MenuItem value="prodigy-tapestry">Prodigy at Tapestry</MenuItem>
                    <MenuItem value="prodigy-winthrop">Prodigy at Winthrop</MenuItem>
                    <MenuItem value="prodigy-wellen-park">Prodigy at Wellen Park</MenuItem>
                    <MenuItem value="prodigy-wellington">Prodigy at Wellington</MenuItem>
                    <MenuItem value="prodigy-acadiana">Prodigy at Acadiana</MenuItem>
                    <MenuItem value="prodigy-berkeley">Prodigy at Berkeley</MenuItem>
                </Select>
            </FormControl>
            <TextField
                name="name"
                label="Child Name"
                value={formData.name}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
                sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
            />
            <TextField
                name="age"
                label="Child Age"
                value={formData.age}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
                sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
            />
            <TextField
                name="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
                sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
            />
            <TextField
                name="phoneNumber"
                label="Phone Number"
                type="tel"
                value={formData.phoneNumber}
                onChange={handleChange}
                required
                fullWidth
                margin="normal"
                sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
            />
            <TextField
                name="message"
                label="Message"
                value={formData.message}
                onChange={handleChange}
                required
                fullWidth
                multiline
                rows={4}
                margin="normal"
                sx={{ textAlign: 'left', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
            />
            <Button type="submit" variant="contained" color="primary">
                Submit
            </Button>
        </form>
    );
}

export default ContactForm;
