import {Box, Typography} from "@mui/material";

import ResponsiveAppBar from "../assets/components/ResponsiveAppBar.tsx";
import ContactForm from "../assets/components/ContactForm.tsx";
import letsTalk from "../assets/lets-talk.png";
import ContactFooter from "./ContactFooter.tsx";
import Footer from "./Footer.tsx";

function Locations() {
    return (
        <>
            <Box sx={{}}>
                <ResponsiveAppBar/>
                <Box className={'tile'} sx={{mb:20,}}>
                    <br/>
                    <Box sx={{display:'flex',  flexDirection:{xs: 'column-reverse', md:'row',} }}>
                        <Box sx={{mr:{md:5, xs:0}, mt:5,}}>
                            <ContactForm/>
                        </Box>
                        <Box sx={{maxWidth:'90vw', ml:'auto', mr:'auto',}} className={`image`}>
                            <Typography sx={{marginTop:'unset',textAlign:'center',fontSize:{md:'4rem', xs:'2.5rem',}, fontWeight:'900'}}>Let's Talk!</Typography>
                            <Box component={"img"} src={letsTalk} alt={`Let's Talk`} sx={{maxWidth:{md: '95vw', sm:'90%'} }}/>
                        </Box>

                    </Box>
                </Box>
                <ContactFooter/>
                <Footer/>
            </Box>
        </>
    );
}

export default Locations
